// imported fonts
@font-face {
  font-family: "Bebas";
  src: url("../Fonts/Bebas/69efc83f-ff1e-4b84-aaac-c35e238e33ca.eot?#iefix");
  src: url("../Fonts/Bebas/69efc83f-ff1e-4b84-aaac-c35e238e33ca.eot?#iefix")
      format("eot"),
    url("../Fonts/Bebas/11e21f91-5c9d-4170-908d-d6afa916d2fc.woff2")
      format("woff2"),
    url("../Fonts/Bebas/b336f9f4-ee3c-4257-a4af-d5d2e3671b86.woff")
      format("woff"),
    url("../Fonts/Bebas/213f5fa9-0ccb-4f43-baf8-99d81c93b2ae.ttf")
      format("truetype");
}

$media: screen;

$mobileSmall: 375px;
$mobile: 450px;
$tabletSmall: 600px;
$tablet: 700px;
$desktop: 800px;
$navLinksCutoff: 1000px;
$widescreen: 1100px;
$thiccc: 1400px;

@mixin responsive($breakpoint) {
  @if $breakpoint == mobileSmall {
    @media #{$media} and (max-width: $mobileSmall) {
      @content;
    }
  } @else if $breakpoint == mobile {
    @media #{$media} and (max-width: $mobile) {
      @content;
    }
  } @else if $breakpoint == tabletSmall {
    @media #{$media} and (max-width: $tabletSmall) {
      @content;
    }
  } @else if $breakpoint == tablet {
    @media #{$media} and (max-width: $tablet) {
      @content;
    }
  } @else if $breakpoint == desktopSmallerThan {
    @media #{$media} and (max-width: $desktop) {
      @content;
    }
  } @else if $breakpoint == navLinksCutoffLarger {
    @media #{$media} and (min-width: $navLinksCutoff) {
      @content;
    }
  } @else if $breakpoint == navLinksCutoff {
    @media #{$media} and (max-width: $navLinksCutoff) {
      @content;
    }
  } @else if $breakpoint == desktopSmall {
    @media #{$media} and (min-width: $tablet) {
      @content;
    }
  } @else if $breakpoint == desktop {
    @media #{$media} and (min-width: $desktop) {
      @content;
    }
  } @else if $breakpoint == widescreen {
    @media #{$media} and (min-width: $widescreen) {
      @content;
    }
  } @else if $breakpoint == thiccc {
    @media #{$media} and (min-width: $thiccc) {
      @content;
    }
  }
}

// Colors ////////////////////////////////////////////
$standard-text: black;
$background: black;
$background-text: white;
$background-transparent: #ffffffd6;
$primary-background-transparent: #2fe2d180;
$primary: white; //#190b21; //rgb(48, 38, 38);
$primary-text: black; //#2fe2d1;
$primary-text-nonAccent: white;
$secondary: #2cc0b1;
$secondary-text: #190b21; //rgb(48, 38, 38);
$accent-dark: #62617b;
$accent-dark-text: white;
$accent-bright: rgb(255, 126, 20); //#ff1d52;
$accent-bright-text: #eedbdc;
$ab-theme-medium: #6dd8ff;
$form-background-color: #e4e4f7;
$form-area-background: #a2faf1a0;
$form-area-background-dark: #a2faf1f0;
$form-color: $primary;

$eat-background: black;
$eat-background-text: white;
$eat-background-transparent: #ffffffa0;
$eat-primary: #2cc0b1; //#a5d397; //#99ff99; //#e07011;
$eat-primary-text: black;
$eat-primary-background-transparent: #c9e7d280;
$eat-primary-text-nonAccent: black;
$eat-secondary: rgb(255, 126, 20); //#a5d397; //#2cc0b1; //#318cd1;
$eat-secondary-text: #093b46;
$eat-accent-dark: #a5d397; //rgb(48, 38, 38);
$eat-accent-dark-text: white;
$eat-accent-bright: #092709; //#99ff99; //#5dd43f;
$eat-link: #4dc42f;
$eat-accent-bright-text: black;
$eat-form-background-color: #f1f1ff;
$eat-form-area-background: #c9e7d2a0;

$eat-form-color: $eat-primary;

// Fonts /////////////////////////////////////////////
$standard-font: "Metrophobic";
$heading-font: "Poppins";
$link-font: "Bebas";
$name-font: "Poppins";
$builtByLink-font: "Orbitron";

// Sizes /////////////////////////////////////////////
$size-tiny: 0.5rem;
$size-small: 0.65rem;
$size-medium: 0.75rem;
$size-large: 1rem;
$size-subheading: 1.25rem;
$size-heading: 1.5rem;
$size-pageHeading: 2rem;

$header-image-height-desktop: 50vh;
$header-image-height-mobile: 80vw;

$link-height: 1rem;
$service-link-height-desktop: 1.5rem;
$mobileLink-height: 1.25rem;
$serviceLinks-width: 8rem;
$topNav-height: 5rem;
$topNav-heightMobile: 2rem;
$stickyNav-height: 1.75rem;

$form-height: 16rem;
$form-input-width: 20rem;
$form-input-spacing: 0.25rem;
$form-header-font-size: 2rem;
$form-message-input-min-height: 6rem;

// Text Outline //////////////////////////////////////
$header-outline: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
  1px 1px 0 #fff;

// MixIns //////////////////////////////////////////
@mixin shadow($color, $thickness) {
  text-shadow: (-$thickness) (-$thickness) 0 $color,
    $thickness (-$thickness) 0 $color, -$thickness $thickness 0 $color,
    $thickness $thickness 0 $color !important;
}

@mixin title {
  font-family: $heading-font;
  color: $primary-text;
  font-weight: 400;
  font-size: $size-pageHeading;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding: 0.25rem;
  text-align: center;

  @include responsive(tablet) {
    font-size: $size-heading;
  }

  @include responsive(mobile) {
    font-size: $size-subheading;
  }
}

@mixin subtitle {
  font-family: $heading-font;
  color: $secondary-text;
  font-weight: 400;
  font-size: $size-subheading;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding: 0.25rem;
  text-align: center;

  @include responsive(tablet) {
    font-size: $size-large;
  }

  @include responsive(mobile) {
    font-size: $size-large;
  }
}

@mixin standardFont {
  font-family: $standard-font;
  color: $standard-text;
  text-decoration: none;
  font-size: $size-medium;

  @include responsive(tabletSmall) {
    font-size: $size-small;
  }
}

@mixin parallax {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $header-image-height-mobile;

  @include responsive(widescreen) {
    height: $header-image-height-desktop;
  }

  @supports (-webkit-overflow-scrolling: touch) {
    background-attachment: scroll;
  }
}

@mixin headerText {
  font-family: $size-heading;
  font-size: $heading-font;
  color: $primary;
  text-shadow: $header-outline;
  text-align: center;

  @include responsive(mobile) {
    font-size: $size-subheading;
  }
}

@mixin backgroundImage {
  background-size: 100%;
}

@mixin linkText {
  color: $accent-bright;
  text-decoration: none;
  border-bottom: 2px solid $accent-bright;
  transition: all 0.2s linear;
  font-weight: normal;

  &:hover {
    font-weight: bold;
    border-bottom: 4px solid $accent-bright;
  }
}

@mixin tag {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: black;
  @include standardFont;
  font-weight: bold;
  min-width: 1.5rem;
  min-height: 1.5rem;
  margin: 0.25rem;

  @include responsive(mobile) {
    min-width: 1rem;
    min-height: 1rem;
    font-size: $size-tiny !important;
  }
}

@mixin search {
  box-sizing: border-box;
  width: 92%;
  margin: auto;
  background-color: $background-transparent;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  max-height: 2.5rem;

  @include responsive(widescreen) {
    width: 80%;
  }
}

@mixin searchBarAndIcon {
  display: flex;
  justify-content: center;
  width: 100%;
}

@mixin searchBar {
  box-sizing: border-box;
  padding: 0.25rem;
  height: 1rem;
  margin: 0;
  border: 1px solid $accent-dark;
  border-radius: 2px;
  flex-grow: 1;
  max-width: 70%;
  transition: box-shadow 0.3s linear;

  &:focus,
  &:hover {
    outline: none;
    box-shadow: 0px 0px 0.3rem $accent-bright;
  }
}

@mixin searchIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 0.25rem;
  background-color: $accent-bright-text;
  cursor: pointer;
  transition: box-shadow 0.3s linear;

  &:hover {
    box-shadow: 0px 0px 0.3rem $accent-bright;
  }
}

@mixin searchIcon {
  color: $accent-bright;
  font-size: $size-large;
}

@mixin searchResultCount {
  display: flex;
  align-items: center;
  justify-content: center;
  text-indent: 0 !important;
  padding-bottom: 0.1rem !important;
  padding-top: 0.25rem !important;
  @include standardFont;
  width: 100% !important;
  color: $accent-bright;
  font-weight: bold;
}

@mixin searchKeyboardHider {
  height: 0px;
  overflow: hidden;
  visibility: hidden;
}
