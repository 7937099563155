@import "../../../Styles/master";

@mixin widthBackground {
  width: 92%;
  margin: auto;
  background-color: $background-transparent;

  @include responsive(widescreen) {
    width: 80%;
  }
}

#recipe {
  min-height: 80vh;
  @include backgroundImage;

  .recipe-title {
    box-sizing: border-box;
    @include widthBackground;
    @include title;
  }

  .recipe-images {
    @include widthBackground;
  }

  .recipe-ingredients,
  .recipe-instructions {
    @include widthBackground();

    .recipe-ingredientsTitle,
    .recipe-instructionsTitle {
      text-align: left !important;
      @include subtitle;
    }

    .recipe-ingredientsList,
    .recipe-instructionsList {
      margin: 0;

      .recipe-ingredient,
      .recipe-instruction {
        @include standardFont;
      }
    }
  }

  .recipe-tags {
    display: flex;
    @include widthBackground;

    .recipe-tag {
      @include tag;
    }
  }
}
