@import "../../../Styles/master";

.noticeBar-empty {
  height: 0 !important;
  overflow: hidden;
  display: none;
}

#noticeBar {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 1rem;
  text-align: center;
  font-weight: 600;
  background-color: $eat-secondary;

  #noticeBar-notice {
    max-width: 95%;
    font-family: $standard-font;
    font-size: $size-tiny;
    color: $eat-secondary-text;
    font-weight: bold;

    @include responsive(tabletSmall) {
      font-size: $size-tiny;
    }
  }
}
