@import "../../../Styles/master";

$border: 15px;

#header {
  @include parallax;
  height: 15rem;
  .header-text {
    position: absolute;
    bottom: 5%;
    left: 0;
  }
}

.eat {
  .header-text {
    background-color: $eat-primary-background-transparent !important;
    color: $eat-primary !important;
  }
  &#header-noImage {
    color: $eat-primary !important;

    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.header-text {
  margin: 0;
  font-family: $heading-font;
  font-size: $size-pageHeading;
  color: $primary;
  background-color: $primary-background-transparent;
  padding: 0.1rem;
  padding-right: 0.25rem;
  padding-left: 0.25rem;
  border-top-right-radius: $border;
  border-bottom-right-radius: $border;
  text-align: center;
  font-weight: normal;

  @include responsive(tabletSmall) {
    font-size: $size-heading;
    padding: 0.15rem;
  }

  @include responsive(mobile) {
    font-size: $size-large;
    padding: 0.15rem;
  }
}

#header-noImage {
  box-sizing: border-box;
  border: none;
  width: 92%;
  margin: auto;
  color: $secondary;
  background-color: $background-transparent;

  @include responsive(widescreen) {
    width: 80%;
  }
}
