@import "../../../../Styles/master";

#recipes {
  //   min-height: 80vh;
  @include backgroundImage;
  // display: flex;
  // justify-content: center;

  .recipes-frame {
    width: 92%;
    height: 100vh;
    margin: auto;
    border: none;
    display: block;

    @include responsive(widescreen) {
      width: 80%;
      justify-content: center;
    }

    @include responsive(mobile) {
      width: 100%;
    }
  }
}

// .recipes-tags {
//   width: 92%;
//   margin: auto;
//   background-color: $form-area-background-dark;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   flex-wrap: wrap;

//   @include responsive(widescreen) {
//     width: 80%;
//     justify-content: center;
//   }

//   @include responsive(desktop) {
//     justify-content: center;
//   }

//   .recipes-tagsTitle {
//     @include subtitle;
//     flex-basis: 100%;
//     background-color: $background-transparent !important;
//     display: flex;
//     align-items: center;
//     @include responsive(desktopSmallerThan) {
//       flex-wrap: wrap;
//       justify-content: center;
//       .recipes-clearTagsContainer {
//         display: flex;
//         justify-content: center;
//         flex-basis: 100%;

//         margin-left: 0 !important;
//         padding: 0.1rem !important;
//       }
//     }
//     @include responsive(desktop) {
//       position: relative;
//       .recipes-clearTags {
//         padding: 0.1rem;
//         position: absolute;
//         right: 1rem;
//         top: 0.5rem;
//       }
//     }

//     .recipes-clearTags {
//       width: 5.5rem;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       margin: 0.25rem;
//       margin-bottom: 0.5rem;
//       box-sizing: border-box;
//       box-shadow: 1px 1px 3px 0 $background;
//       border-radius: 5px;
//       background-color: $secondary;
//       transition: background-color 0.3s linear;
//       font-size: $size-small;
//       font-weight: bold;

//       &:hover {
//         background-color: $accent-bright;
//       }
//     }
//   }

//   .recipes-tagContainer {
//     border-bottom: 2px solid $secondary;
//     display: flex;
//     align-items: center;
//     flex-wrap: nowrap;
//     position: relative;

//     @include responsive(tablet) {
//       flex-grow: 1;
//       justify-content: flex-start;
//     }

//     &:hover {
//       cursor: pointer;

//       .recipes-tagFullname {
//         visibility: visible !important;
//       }
//     }

//     .recipes-tag {
//       @include tag;
//     }

//     .recipes-tagFullname {
//       text-align: center;
//       padding-bottom: 0.25rem;
//       flex-basis: 100%;
//       max-width: 2rem;
//       flex-shrink: 1;
//       font-size: $size-tiny;
//       font-weight: bold;
//       word-break: break-word;
//     }
//   }
// }

// .recipes-search {
//   @include search;
//   .recipes-searchBarAndIcon {
//     @include searchBarAndIcon;
//     .recipes-searchIconContainer {
//       @include searchIconContainer;
//       .recipes-searchIcon {
//         @include searchIcon;
//       }
//     }
//     .recipes-searchBar {
//       @include searchBar;
//     }
//   }
//   .recipes-searchResultCount {
//     @include searchResultCount;
//   }
// }

// .recipes-resultsView {
//   padding-top: 0.25rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-wrap: wrap;
//   width: 92%;
//   margin: auto;
//   background-color: $background-transparent;

//   @include responsive(widescreen) {
//     width: 80%;
//   }

//   .recipes-resultLayoutOption {
//     cursor: pointer;
//     color: $secondary-text;
//     padding-right: 0.25rem;
//     padding-left: 0.25rem;
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: center;
//     align-items: cetner;

//     &.recipes-resultLayoutActive {
//       color: $secondary;
//     }
//   }

//   .recipes-layoutTitle {
//     font-weight: bold;
//     font-size: $size-small;
//     text-align: center;
//     flex-basis: 100%;
//   }
// }

// .recipes-recipeLink {
//   text-decoration: none;
//   color: $secondary-text;
// }

// .recipes-recipesContainer {
//   width: 92%;
//   margin: auto;
//   background-color: $background-transparent;
//   display: flex;
//   flex-wrap: wrap;
//   align-items: flex-start;
//   min-height: 40vh;

//   &.recipes-recipesContainertitle {
//     display: block;
//   }

//   @include responsive(widescreen) {
//     width: 80%;
//   }

//   .recipes-recipe {
//     cursor: pointer;
//     box-sizing: border-box;

//     &:not(:last-child) {
//       &.recipes-recipetitle {
//         border-bottom: 3px solid $secondary;
//       }
//     }

//     .recipes-recipeHeader {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       flex-wrap: wrap;

//       @include responsive(desktopSmallerThan) {
//         flex-wrap: wrap;
//       }

//       .recipes-recipeTitle {
//         @include subtitle;
//       }

//       .recipes-recipeTags {
//         display: flex;

//         .recipes-recipeTag {
//           @include tag;
//         }
//       }
//     }

//     .recipes-recipeRead {
//       display: none;
//     }

//     &.recipes-recipetitle {
//       width: 100%;

//       .recipes-recipeHeader {
//         justify-content: flex-start;
//         flex-wrap: nowrap;

//         @include responsive(desktopSmallerThan) {
//           flex-wrap: wrap;
//           .recipes-recipeTitle {
//             text-align: left !important;
//             font-size: $size-large !important;

//             max-width: 70%;
//           }
//         }

//         @include responsive(mobile) {
//           .recipes-recipeTitle {
//             font-size: $size-medium !important;
//           }
//         }
//       }

//       .recipes-recipeDate {
//         white-space: nowrap;
//       }
//       .recipes-recipePreview,
//       .recipes-recipeImage {
//         display: none;
//       }
//     }

//     &.recipes-recipegrid {
//       margin: 2.5%;
//       border-radius: 5px;
//       box-shadow: 2px 2px 3px 0 $accent-dark;
//       overflow: hidden;

//       max-height: 35vh;

//       @include responsive(mobile) {
//         max-height: 95vw;
//       }

//       @include responsive(desktopSmallerThan) {
//         flex-basis: 95%;
//       }

//       @include responsive(desktop) {
//         flex-basis: 45%;
//       }

//       @include responsive(thiccc) {
//         flex-basis: 28%;
//       }

//       .recipes-recipeHeader {
//         border-top-left-radius: 5px;
//         border-top-right-radius: 5px;
//         background-color: $primary-background-transparent;
//         border-bottom: 3px solid $accent-bright;
//       }
//       .recipes-recipeTitle {
//         font-size: $size-medium !important;
//         font-weight: bold;
//         flex-basis: 100%;
//         word-wrap: nowrap;
//         text-overflow: ellipsis;
//       }

//       .recipes-recipeImage {
//         max-width: 100%;
//         overflow: hidden;
//         height: 30vh;
//         background-size: cover;
//         background-position: center;
//         background-color: $primary;

//         @include responsive(mobile) {
//           height: 90vw;
//         }
//       }

//       .recipes-recipePreview {
//         display: none;
//       }
//     }

//     &.recipes-recipepreview {
//       padding-top: 1rem;
//       max-height: 80vh;
//       overflow: hidden;
//       position: relative;
//       width: 100%;

//       .recipes-recipeImage {
//         display: none;
//       }

//       .recipes-recipePreview {
//         margin-bottom: 1rem;
//       }

//       .recipes-recipeRead {
//         background-color: $secondary-text;
//         color: $secondary;
//         width: 100%;
//         display: block;
//         position: absolute;
//         bottom: 0;
//         text-align: center;
//       }
//     }
//   }
// }
