@import "../../../Styles/master";

.eat {
  .content-title {
    color: $eat-secondary-text !important;

    //background-color: $eat-secondary !important;
  }

  .content-subtitle {
    color: $eat-secondary !important;
  }

  .content-listTitle {
    color: $eat-secondary !important;
  }

  .content-linkText {
    color: $eat-link !important;
    border-bottom: 2px solid $eat-accent-dark !important;

    &:hover {
      border-bottom: 4px solid $eat-link !important;
    }
  }

  .content-listElement {
    &::before {
      color: $eat-accent-bright !important;
    }
  }

  .content-profileLink {
    .content-profileInfo {
      background-color: $eat-primary !important;
      box-shadow: 1px 1px 3px 1px black;

      .content-profileCardName {
        color: $eat-accent-bright !important;
        background-color: $eat-primary !important;
      }
      .profile-qualificationsTitle,
      .content-expertiseTitle {
        color: $eat-accent-bright !important;
      }

      .content-profileQualification,
      .content-profileExpertise {
        color: $eat-primary-text-nonAccent !important;
      }
    }
  }
}

.content {
  width: 92%;
  margin: auto;
  background-color: $background-transparent;
  padding-top: 0.5rem;

  @include responsive(widescreen) {
    width: 80%;
  }
}

.content-item {
  @include standardFont;

  transition: all 0.3s linear;
  word-wrap: break-word;
}

.content-titleContainer {
  margin: 0 !important;
  margin: auto !important;
  // max-width: 90%;
  display: flex;
  margin-top: 1.5rem;
  justify-content: center;
  align-items: center;
}

.content-bold {
  font-weight: bold;
}

.content-italics {
  font-style: italic;
  padding-right: 0.05rem;
}

.content-title {
  @include title;
  // background-color: $primary;
  // border-radius: 15px;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.content-subtitleContainer {
  margin: 0;

  .content-subtitle {
    @include subtitle;
  }
}

.content-halfWidth {
  flex-basis: 50%;
  padding: 0.25rem;
  box-sizing: border-box;

  @include responsive(tablet) {
    flex-basis: 100%;
  }
}

.content-linkText {
  @include linkText;
}

.content-listContainer {
  padding: 0.25rem;
  padding-bottom: 0;
  box-sizing: border-box;
  width: 100%;

  .content-listTitle {
    @include subtitle;
    padding-top: 0 !important;
  }

  .content-list {
    margin: 0;
    padding: 0;
    padding-left: 10%;
    list-style: none;

    @include responsive(desktop) {
      padding-right: 10%;
    }

    .content-listElement {
      padding-bottom: 0.25rem;

      &::before {
        content: "\2022";
        color: $accent-bright;
        font-weight: bold;
        position: absolute;
        transform: translateX(-0.5rem);
      }
    }
  }
}

.content-paragraphs {
  padding: 0.25rem;
  padding-top: 0;
  box-sizing: border-box;

  @include responsive(desktop) {
    width: 90%;
    margin: auto;
  }

  .content-paragraphText {
    padding-top: 0.5rem;
  }
}

.content-paragraphsAndImage {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.content-fileDownloadsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .content-fileDownloadContainers {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.25rem;
    margin-bottom: 0.5rem;
    box-sizing: border-box;
    box-shadow: 1px 1px 3px 0 $background;
    border-radius: 5px;
    background-color: $secondary;
    transition: background-color 0.3s linear;

    &:hover {
      background-color: $accent-bright;
    }

    .content-fileDownload {
      padding: 0.25rem;
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
      transition: color 0.3s linear;
      text-align: center;
      color: $secondary-text;
      @include standardFont;

      &:hover {
        color: $accent-bright-text;
      }
    }
  }
}

.content-tagsContainer {
  visibility: hidden;
  height: 0;
}

.content-videoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;

  .content-video {
    width: 70vw;
    height: 39.375vw;
    border: none;

    @include responsive(tabletSmall) {
      width: 100vw;
      height: 56.25vw;
    }

    @include responsive(widescreen) {
      width: 50vw;
      height: 28.125vw;
    }
  }
}

.content-profileCards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 0.5rem;

  .content-profilePadding {
    flex-basis: 50%;
    box-sizing: border-box;
    padding: 1rem;
    padding-bottom: 0;
    @include responsive(tabletSmall) {
      flex-basis: 100%;
    }

    @include responsive(desktop) {
      flex-basis: 50%;
    }

    @include responsive(widescreen) {
      flex-basis: 33%;
      max-width: 12rem;
    }

    .content-profileLink {
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      text-decoration: none;
      color: $standard-text;

      .content-profileCard {
        display: flex;
        flex-direction: column;
      }

      .content-profileCardName {
        font-weight: bold;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        text-align: center;
        color: $secondary-text;
        background-color: $secondary;
        padding: 0.1rem;
        font-size: $size-subheading;

        @include responsive(mobile) {
          font-size: $size-large;
        }
      }

      .content-profilePicContainer {
        box-sizing: border-box;
        display: flex;
        min-width: 100%;
        flex-wrap: wrap;
        align-items: center;
        padding: 0.25rem;
        padding-bottom: 0.2rem;
        justify-content: center;

        .content-profilePicture {
          box-shadow: 1px 1px 3px 1px black;
          border-radius: 50%;
          width: 100%;
          background-size: cover;
          background-position: center;
          box-sizing: border-box;

          &:before {
            content: "";
            float: left;
            padding-top: 100%;
          }
        }
      }

      .content-profileInfo {
        flex-grow: 1;
        padding: 0.1rem;
        background-color: $secondary;
        overflow: hidden;
        border-radius: 15px;
        box-shadow: 1px 1px 3px 1px black;
      }
      .content-profileQualificationsContainer {
        padding-left: 0.1rem;
        display: flex;
        flex-basis: 100%;
        flex-wrap: wrap;
        justify-content: center;
        white-space: pre;
        padding-right: 0.1rem;
        padding-bottom: 0.2rem;

        .profile-qualificationsTitle {
          text-align: center;
          color: $primary;
          flex-basis: 100%;
          font-weight: bold;
        }

        .content-profileQualification {
          color: $primary-text-nonAccent;
          font-size: $size-small;
        }
      }

      .content-profileExpertiseContainer {
        display: flex;
        flex-basis: 100%;
        flex-wrap: wrap;
        justify-content: center;
        white-space: pre-wrap;
        padding-left: 0.1rem;
        padding-right: 0.1rem;
        padding-bottom: 0.1rem;
        word-wrap: break-word;
        text-align: center;

        .content-expertiseTitle {
          flex-basis: 100%;
          color: $primary;
          text-align: center;
          padding-bottom: 0.1rem;
          font-weight: bold;
        }

        .content-profileExpertise {
          color: $primary-text-nonAccent;
          font-size: $size-small;
        }
      }
    }
  }
}

.content-frameContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
