@import "../../../Styles/master";

.map-map {
  border: none;
}

.map,
.map-map {
  box-sizing: border-box;
  width: 100%;
  height: 40vh;
  margin: 0;
  padding: 0;
  //border: none !important;

  @include responsive(tablet) {
    height: 80vw;
  }

  @include responsive(mobile) {
    height: 100vw;
  }
}
