@import "../../../Styles/master.scss";

.eat {
  .separatorLineDark {
    border-bottom: solid 2px $eat-accent-dark !important;
  }

  .separatorLineLight {
    border-bottom: solid 2px $eat-secondary !important;
  }
}

#separatorContainer {
  &.separatorBackground {
    background-color: $background-transparent;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  margin: auto;
  width: 92%;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @include responsive(widescreen) {
    width: 80%;
  }

  .separatorLine {
    margin: auto;
    transition: width 0.5s ease-out;
    width: 0%;
    clear: both;
  }

  .separatorLineDark {
    border-bottom: solid 2px $secondary;
  }

  .separatorLineLight {
    border-bottom: solid 2px $secondary-text;
  }

  .separatorLineGrown {
    width: 80% !important;
  }
}
