@import "../../../Styles/master";

$arrowSize: 1.5rem;

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}

#carousel-container,
#carousel-image {
  background-size: cover;
  background-position: center;
}

#carousel-container {
  height: 40vh;

  @include responsive(widescreen) {
    height: 50vh;
  }

  @include responsive(tablet) {
    height: 70vw;
  }
}

#carousel-container {
  position: relative;
  background-repeat: no-repeat;

  .carousel-captionContainer {
    width: 100%;
    position: absolute;
    bottom: 10%;
    overflow: hidden;

    .carousel-captionTitle {
      max-width: 50%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .carousel-captionTitleText {
        background-color: $secondary;
        color: $secondary-text;
        padding: 0.1rem;
      }
    }

    .carousel-captionCaption {
      font-size: $size-small !important;
      display: flex;

      .carousel-captionCaptionText {
        display: inline-block;
        background-color: $primary;
        color: $primary-text;
        padding: 0.1rem;
        white-space: nowrap;
        overflow: hidden;
        max-width: 60%;
      }

      .carousel-captionLinkContainer {
        .carousel-captionLink {
          box-sizing: border-box;
          display: inline-block;
          background-color: $primary;
          @include linkText;
          border-bottom: 4px solid $primary;
          padding: 0.1rem;
        }
      }
    }
  }

  #carousel-textContainer {
    z-index: 1;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    #carousel-text {
      @include headerText;
    }
  }

  #carousel-image {
    height: 100%;
    background-repeat: no-repeat;
    position: relative;
    animation-duration: 1s;
    animation-fill-mode: forwards;

    &.carousel-imageFade {
      animation-name: fadeOut;
    }
  }

  &:hover {
    .carousel-arrow {
      animation: fadeIn;
      animation-duration: 1s;
    }
  }

  &:not(:hover) {
    .carousel-arrow {
      animation: fadeOut;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    }
  }

  .carousel-arrow {
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    width: $arrowSize;
    height: $arrowSize;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-$arrowSize / 2);
    cursor: pointer;

    @include responsive(tabletSmall) {
      display: none;
    }
  }

  #carousel-arrowRight {
    right: 1rem;
  }

  #carousel-arrowLeft {
    left: 1rem;
  }
}
