@import "../../../Styles/master";

.footer {
  #social {
    position: relative !important;
    justify-content: center !important;

    @include responsive(navLinksCutoffLarger) {
      padding-left: 0.25rem;
      justify-content: flex-start;
      flex-direction: row !important;
      flex-wrap: wrap;
    }
  }
}

#social {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;

  @include responsive(navLinksCutoffLarger) {
    padding-left: 0.25rem;
    position: absolute;
    left: 0;
    max-height: 7rem;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
  }

  &.eat {
    .social-iconImage {
      &:hover {
        box-shadow: 0 0 3px 2px $eat-accent-bright !important;
      }
    }
  }

  .social-iconContainer {
    padding-left: 0.25rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
  }

  .social-iconImage {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;

    &:hover {
      box-shadow: 0 0 3px 2px $accent-bright;
    }

    @include responsive(mobile) {
      width: 0.75rem;
      height: 0.75rem;
    }
  }
}
