@import "../../../Styles/master";

:not(.nav-eat) {
  // account for the offset of active link

  @include responsive(navLinksCutoffLarger) {
    .nav-linkText {
      box-sizing: border-box !important;
    }
    #nav-top {
      .nav-link:not(.nav-link-active) {
        border-bottom: 4px solid $primary;
        // margin-bottom: 4px;
      }

      .nav-link-active:not(.nav-serviceLink) {
        border-bottom: 4px solid $accent-bright;
        // margin-bottom: 4px;
      }
    }

    #nav-sticky {
      .nav-link:not(.nav-link-active) {
        border-bottom: 4px solid $primary;
        margin-bottom: 4px;
      }

      .nav-link-active:not(.nav-serviceLink) {
        border-bottom: 4px solid $accent-bright;
        margin-bottom: 4px;
      }
    }
  }
}

.nav-serviceLinks {
  margin-top: 1px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 1px 1px 3px 1px black;

  &#nav-serviceLinksSticky {
    margin-top: 4px;
  }
}

.nav-eat {
  .nav-link {
    box-sizing: content-box !important;
  }

  .nav-link {
    color: $eat-primary-text !important;

    &:hover,
    &.nav-link-active {
      color: $eat-secondary !important;
    }
  }

  background-color: $eat-primary !important;

  @include responsive(navLinksCutoff) {
    .nav-links {
      background-color: $eat-accent-dark !important;
    }

    .nav-serviceLinks {
      .nav-serviceLinkContainer {
        &:hover {
          .nav-linkText {
            color: $eat-secondary !important;
          }
        }
        .nav-linkText {
          color: $eat-primary-text !important;
        }
        &:not(last-child) {
          border-top: 1px solid $eat-accent-bright !important;
        }
      }
    }
  }

  .nav-link {
    color: $eat-primary-text !important;

    &:hover,
    &.nav-link-active {
      color: $eat-secondary !important;
    }
  }

  .nav-menuContainer {
    .nav-menuIcon {
      color: $eat-primary-text !important;
    }
  }

  .nav-expandServicesIconContainer {
    .nav-expandServicesIcon {
      color: $eat-primary-text !important;
    }
  }

  @include responsive(navLinksCutoffLarger) {
    .nav-serviceLink.nav-link-active,
    .nav-serviceLink:hover {
      .nav-linkText {
        color: $accent-bright !important;
      }

      #nav-serviceLinksTop,
      #nav-serviceLinksSticky {
        background-color: $eat-secondary !important;
      }
    }

    #nav-top {
      .nav-link:not(.nav-link-active) {
        border-bottom: 4px solid $eat-primary !important;
      }

      .nav-link-active:not(.nav-serviceLink) {
        border-bottom: 4px solid $eat-secondary !important;
      }
    }

    #nav-topDesktopLinksContainer,
    #nav-stickyDesktopLinksContainer {
      .nav-serviceLinks {
        background-color: $eat-primary !important;
        .nav-serviceLinkContainer {
          .nav-serviceLink {
            color: $eat-primary-text !important;
          }
        }
      }
    }

    #nav-sticky {
      .nav-linkContainer {
        #nav-serviceLinksSticky {
          .nav-serviceLinkContainer {
            .nav-linkText {
              @include responsive(navLinksCutoffLarger) {
                color: $eat-secondary-text !important;
              }
            }
            .nav-serviceLink.nav-link-active,
            .nav-serviceLink:hover {
              .nav-linkText {
                color: $eat-accent-dark-text !important;
              }
            }
          }
        }
      }

      .nav-link:not(.nav-link-active) {
        // margin-bottom: 4px;
        border-bottom: 4px solid $eat-primary !important;
      }

      .nav-link-active:not(.nav-serviceLink) {
        // margin-bottom: 4px;
        border-bottom: 4px solid $eat-secondary !important;
      }
    }

    .nav-linkContainer {
      #nav-serviceLinksTop,
      #nav-serviceLinksSticky {
        @include responsive(navLinksCutoffLarger) {
          background-color: $eat-secondary !important;

          .nav-serviceLinkContainer {
            .nav-linkText {
              color: $eat-secondary-text !important;
            }
            .nav-serviceLink.nav-link-active,
            .nav-serviceLink:hover {
              .nav-linkText {
                color: $eat-accent-dark-text !important;
              }
            }

            &:not(:first-child) {
              border-top: 1.5px solid $eat-secondary-text !important;
            }
          }
        }
      }
    }
  }

  #nav-sticky {
    background-color: $eat-primary !important;

    .nav-servicesSlideDown {
      .nav-serviceLinks {
        width: 100% !important;

        &:not(:first-child) {
          border-top: 1.5px solid $eat-primary-text !important;
        }
      }
    }

    #nav-serviceLinksSticky {
      .nav-serviceLinkContainer {
        &:hover {
          .nav-serviceLink {
            color: $eat-accent-bright-text !important;
          }
        }

        @include responsive(navLinksCutoffLarger) {
          .nav-serviceLink {
            .nav-linkText {
              color: $eat-secondary-text !important;
            }
          }

          &:not(:first-child) {
            border-top: 1.5px solid $eat-secondary-text !important;
          }
        }
      }
    }
  }
}

@include responsive(navLinksCutoffLarger) {
  .nav-serviceLinkContainer,
  .nav-serviceLink {
    height: $service-link-height-desktop !important;
  }
}

@include responsive(navLinksCutoff) {
  #nav-topDesktopLinksContainer,
  #nav-stickyDesktopLinksContainer {
    display: none !important;
  }

  .nav-linkText {
    @include responsive(mobileSmall) {
      max-width: 80%;
    }
  }

  .nav-serviceLinks {
    box-shadow: none !important;
    .nav-serviceLinkContainer {
      &:not(last-child) {
        border-top: 1px solid $accent-bright;
      }
    }

    .nav-serviceLink {
      font-size: $size-tiny;
      width: 100%;
    }
  }

  .nav-expandServicesIconContainer {
    position: absolute;
    // display: inline-flex;
    height: $link-height;
    bottom: 0;
    margin-right: 0;
    left: 100;

    @include responsive(mobileSmall) {
      margin: 0 !important;
      //width: 100%;
    }
  }

  .nav-linkText {
    position: relative;
    text-align: center;
  }
}

@include responsive(navLinksCutoffLarger) {
  .nav-hidden {
    display: none;
  }
  #nav-topMenuContainer,
  #nav-stickyMenuContainer {
    display: none !important;
  }
}

#nav {
  letter-spacing: 2px;
  transition: color 0.4s linear;
  transition: background-color 0.4s linear;

  background-color: $primary;
  font-family: $link-font;
  font-size: $size-small;

  .nav-linksSlideDown,
  .nav-servicesSlideDown {
    transition-duration: 0.3s;
    overflow: hidden !important;
  }

  .nav-menuContainer {
    display: none;
  }

  .nav-menuContainer,
  #nav-topLogoContainer,
  #nav-stickyLogoContainer,
  .nav-linkContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-link {
    overflow: hidden;
  }

  .nav-link,
  .nav-serviceLink {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: $primary-text;
    min-height: $link-height;

    &:hover,
    &.nav-link-active {
      color: $accent-bright;
    }
  }

  .nav-menuContainer {
    position: absolute;
    right: 0.25rem;

    .nav-menuIcon {
      color: $primary-text;
      font-size: $size-heading;
    }
  }

  #nav-topLogo {
    max-width: 60vw;
    max-height: $topNav-height;
    padding: 0.1rem;
  }

  .nav-expandServicesIconContainer {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: -1rem;

    .nav-expandServicesIcon {
      font-size: $size-large;
      color: $primary-text;
    }
  }

  #nav-topDesktopLinksContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    .nav-links {
      display: flex;
      align-items: center;
      min-height: $link-height;

      .nav-linkContainer {
        min-height: $link-height;
        flex-wrap: wrap;

        .nav-link {
          display: inline-flex;
          min-height: $link-height;

          .nav-linkText {
            display: inline-flex;
            align-items: center;
            min-height: $link-height;
          }

          .nav-serviceLinks {
            width: $serviceLinks-width;
            background-color: $primary;
            text-align: center;

            .nav-serviceLinkContainer {
              display: flex;
              align-items: center;
              justify-content: center;
              height: $service-link-height-desktop !important;

              .nav-serviceLink {
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                font-family: $link-font;
                font-size: $size-small;
                color: $primary-text;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }

  #nav-top {
    #nav-topLogoHomeLink {
      text-decoration: none;
      min-height: $topNav-height;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 0.25rem;
      padding-top: 0.25rem;

      #nav-topLogo {
        max-height: $topNav-height;
      }

      @include responsive(mobile) {
        min-height: $topNav-heightMobile;

        #nav-topLogo {
          max-height: $topNav-heightMobile;
        }
      }
    }

    @include responsive(navLinksCutoff) {
      #nav-topMenuContainer {
        display: flex;
      }

      .nav-links {
        flex-wrap: wrap;

        .nav-linkContainer {
          width: 100%;
          min-height: $mobileLink-height !important;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          border-top: 1.5px solid $background-text;

          .nav-link {
            width: 100%;
            min-height: $mobileLink-height;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .nav-servicesSlideDown {
            width: 100%;

            #nav-serviceLinksTop {
              display: block !important;
              width: 100%;

              .nav-serviceLinkContainer {
                min-height: $link-height;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }

          &:not(:hover) {
            .nav-serviceLinks {
              display: none;
            }
          }
        }
      }
    }

    .nav-links {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .nav-link {
        font-size: $size-small;
      }
    }

    @include responsive(navLinksCutoffLarger) {
      align-items: center;

      .nav-linkContainer {
        position: relative;
        &:not(:first-child) {
          margin-left: 1.5rem;
        }

        .nav-expandServicesIconContainer {
          margin-right: 0;
          position: absolute;
          right: -0.85rem;
        }

        #nav-serviceLinksTop {
          width: 100%;
          right: 0;
          top: $link-height + 0.1rem;
          z-index: 1;
          background-color: $secondary;

          .nav-serviceLinkContainer {
            &:hover {
              .nav-linkText {
                color: $accent-bright;
              }
            }

            .nav-serviceLink,
            .nav-linkText {
              font-size: $size-small;
              color: $secondary-text;
            }

            &:not(:first-child) {
              border-top: 1.5px solid $secondary-text;
            }
          }
        }

        &:not(:hover) {
          .nav-serviceLinks {
            display: none;
          }
        }

        &:hover {
          .nav-serviceLinks {
            position: absolute;
          }
        }
      }
    }

    @include responsive(navLinksCutoffLarger) {
      .nav-linkContainer {
        &:not(:first-child) {
          margin-left: 1.5rem;
        }
      }
    }

    @include responsive(widescreen) {
      .nav-linkContainer {
        &:not(:first-child) {
          margin-left: 3rem;
        }
      }
    }
  }

  #nav-sticky {
    background-color: $primary;
    height: $stickyNav-height;
    width: 100%;
    z-index: 1;
    position: fixed;
    transition: top 0.3s linear;
    top: -$stickyNav-height - 0.1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @include responsive(navLinksCutoff) {
      flex-wrap: wrap;

      #nav-stickyLogoHomeLink {
        display: flex;
        width: 100%;
      }

      #nav-stickyMenuContainer {
        display: flex;
      }

      .nav-linksSlideDown {
        width: 100%;

        .nav-links {
          flex-wrap: wrap;
          width: 100%;
          font-size: $size-small;
          background-color: $primary;

          &.nav-mobileLinksShown {
            height: auto;
          }

          .nav-linkContainer {
            padding: 0;
            margin: 0;
            width: 100%;
            min-height: $mobileLink-height !important;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            border-top: 1.5px solid $background-text;

            .nav-link {
              width: 100%;
              min-height: $mobileLink-height;
              align-items: center;
              justify-content: center;
            }

            .nav-servicesSlideDown {
              width: 100%;

              .nav-serviceLinks {
                display: block;
                width: 100%;

                .nav-sublistLinkContainer {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }

    @include responsive(mobileSmall) {
      .nav-stickyMenuIcon {
        font-size: 1rem;
      }
    }

    @include responsive(navLinksCutoffLarger) {
      min-height: $stickyNav-height !important;
      display: inline-flex;

      .nav-stickyLinksSlideDown:not(:hover) {
        display: none;
      }

      #nav-stickyLogoContainer {
        display: inline-flex !important;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }

      #nav-stickyDesktopLinksContainer {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .nav-link {
          box-sizing: border-box;
        }

        .nav-links {
          display: inline-flex !important;

          .nav-linkContainer {
            position: relative;
            min-height: $stickyNav-height;

            &:not(:hover) {
              #nav-serviceLinksSticky {
                display: none !important;
              }
            }

            &:hover {
              #nav-serviceLinksSticky {
                position: absolute;
              }
            }
          }

          .nav-linkContainer {
            &:not(:first-child) {
              margin-left: 0.7rem;
            }
            .nav-link {
              font-size: $size-small;
              display: flex;
              align-items: center;
              min-height: $stickyNav-height;

              .nav-linkText {
                box-sizing: border-box;
                white-space: nowrap;
                padding-top: 0.65rem;
                min-height: $stickyNav-height;
                font-size: $size-small;
                vertical-align: middle;
                display: flex;
                vertical-align: middle;
              }
            }
            .nav-servicesSlideDown {
              .nav-serviceLinks {
                .nav-serviceLink {
                  &:hover {
                    .nav-linkText {
                      color: $accent-dark-text;
                    }
                  }
                }

                .nav-serviceLinkContainer:hover,
                .nav-link-active {
                  color: $accent-bright;
                }

                .nav-linkText {
                  font-size: $size-small;
                  color: $secondary-text;
                }

                &:not(:first-child) {
                  border-top: 1.5px solid $primary-text;
                }
              }
            }
          }
        }
      }

      .nav-expandServicesIconContainer {
        margin-right: 0;
        position: absolute;
        right: -0.85rem;
        top: 25%;
      }

      #nav-serviceLinksSticky {
        width: 100%;
        left: 0;
        top: $stickyNav-height;
        z-index: 1;
        background-color: $secondary;

        .nav-serviceLinkContainer {
          &:hover {
            .nav-serviceLink {
              color: $accent-bright;
            }
          }

          .nav-serviceLink {
            .nav-linkText {
              font-size: $size-small;
              color: $secondary-text;
            }
          }

          &:not(:first-child) {
            border-top: 1.5px solid $secondary-text;
          }
        }
      }
    }

    @include responsive(navLinksCutoffLarger) {
      .nav-linkContainer {
        &:not(:first-child) {
          margin-left: 1.5rem !important;
        }

        .nav-link {
          font-size: $size-small;
        }
      }
    }

    @include responsive(widescreen) {
      .nav-linkContainer {
        &:not(:first-child) {
          margin-left: 2rem !important;
        }
      }
    }

    &.nav-stickyNavigationShown {
      top: 0;
    }

    #nav-stickyLogoHomeLink {
      text-decoration: none;
      height: $stickyNav-height;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      #nav-stickyLogo {
        max-height: 1.5rem;
        max-width: 10rem;
        padding: 0.1rem;
      }
    }
  }
}
