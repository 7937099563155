@import "../../../../Styles/master";

#expertise {
  min-height: 80vh;
  @include backgroundImage;
}

.expertise-specialtiesLinks {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  width: 92%;
  background-color: $background-transparent;

  @include responsive(widescreen) {
    width: 80%;
  }

  .expertise-link {
    cursor: pointer;
    background-color: $secondary-text;
    color: $secondary;
    padding: 0.25rem;
    text-align: center;
    margin: 0.25rem;
    border-radius: 3px;
    box-shadow: 1px 1px 3px 0 $background;
    transition: box-shadow 0.2s linear;

    &:hover {
      box-shadow: 1px 1px 6px 2px $accent-bright;
    }

    @include responsive(mobile) {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      word-wrap: break-word;
    }
  }
}
