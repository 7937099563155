@import "../../../Styles/master";

.imagePane {
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  opacity: 1;
  transition: opacity 1s linear !important;

  &.imagePane-transitioning {
    opacity: 0;
  }

  .imagePane-caption {
    width: 100%;
    padding-top: 0.25rem;
    text-align: center;
    font-size: $size-small !important;
  }

  .imagePane-mainImage {
    width: 100%;
    max-height: 50vh;
    background-size: contain;
    background-position-x: center;
    background-repeat: no-repeat;

    .imagePane-ghostImage {
      width: 100%;
      max-height: 50vh;
    }
  }

  .imagePane-imageTiles {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .imagePane-imageTile {
      transition: background-image 1s linear !important;
      box-sizing: border-box;
      background-size: cover;
      background-position: center;
      margin: 4px;
      outline: solid 4px $primary;
      transition: all 0.3s linear;
      flex-basis: 15%;

      @include responsive(mobile) {
        flex-basis: 20%;
      }

      @include responsive(widescreen) {
        flex-basis: 10%;
      }

      &:not(.imagePane-imageTileSelected) {
        cursor: pointer;
      }

      &:hover {
        outline: solid 4px $secondary;
      }

      &.imagePane-imageTileSelected {
        outline: solid 4px $secondary;
      }

      &:before {
        content: "";
        float: left;
        padding-top: 100%;
      }
    }
  }
}
