@import "../../../Styles/master";

#article {
  @include backgroundImage;
  min-height: 80vh;

  #article-titleContainer {
    padding-top: 0.5rem;
    width: 92%;
    margin: auto;
    background-color: $background-transparent;

    @include responsive(widescreen) {
      width: 80%;
    }
  }

  #article-title {
    @include title;
  }

  #article-date {
    font-size: $size-small;
    font-weight: bold;
    text-align: right;
    padding-right: 1rem;

    @include responsive(tabletSmall) {
      padding-right: 0.5rem;
    }
  }
}
