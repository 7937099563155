@import "../../../Styles/master";

.bam {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  .bam-container {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0.5rem;

    @include responsive(desktop) {
      margin-left: auto;
    }

    &:hover {
      .bam-text {
        color: $accent-bright;
      }
    }

    .bam-text {
      font-family: "Bangers";
      font-size: 1rem;
      color: white;
      text-align: right;
      padding-right: 0.5rem;
      transition: color 0.3s linear;
      letter-spacing: 0.75px;
      @include shadow(black, 1px);

      @include responsive(tablet) {
        font-size: 0.75rem;
        color: $accent-bright;
      }
    }

    .bam-logo {
      width: auto;
      height: 2rem;
    }
  }
}
