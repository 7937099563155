@import "../../../../Styles/master";

#news {
  min-height: 80vh;
  @include backgroundImage;
}

.news-search {
  @include search;
  .news-searchBarAndIcon {
    @include searchBarAndIcon;
    .news-searchIconContainer {
      @include searchIconContainer;
      .news-searchIcon {
        @include searchIcon;
      }
    }
    .news-searchBar {
      @include searchBar;
    }
  }
  .news-searchResultCount {
    @include searchResultCount;
  }
}

.news-resultsView {
  padding-top: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 92%;
  margin: auto;
  background-color: $background-transparent;

  @include responsive(widescreen) {
    width: 80%;
  }

  .news-resultLayoutOption {
    cursor: pointer;
    color: $secondary-text;
    padding-right: 0.25rem;
    padding-left: 0.25rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: cetner;

    &.news-resultLayoutActive {
      color: $secondary;
    }
  }

  .news-layoutTitle {
    font-weight: bold;
    font-size: $size-small;
    text-align: center;
    flex-basis: 100%;
  }
}

.news-articleLink {
  text-decoration: none;
  color: $secondary-text;
}

.news-articlesContainer {
  width: 92%;
  margin: auto;
  background-color: $background-transparent;
  display: flex;
  flex-wrap: wrap;

  @include responsive(widescreen) {
    width: 80%;
  }

  .news-article {
    cursor: pointer;
    box-sizing: border-box;

    &:not(:last-child) {
      &.news-articletitle {
        border-bottom: 3px solid $secondary;
      }
    }

    .news-articleHeader {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      @include responsive(desktopSmallerThan) {
        flex-wrap: wrap;
      }

      .news-articleTitle {
        @include subtitle;
        font-weight: bold !important;
      }

      .news-articleDate {
        font-weight: bold;
        font-size: $size-small;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    .news-articleRead {
      display: none;
    }

    &.news-articletitle {
      width: 100%;

      .news-articleHeader {
        justify-content: flex-start;

        flex-wrap: nowrap;

        @include responsive(desktopSmallerThan) {
          flex-wrap: wrap;
        }
      }

      .news-articleDate {
        white-space: nowrap;
      }
      .news-articlePreview,
      .news-articleImage {
        display: none;
      }
    }

    &.news-articlegrid {
      margin: 2.5%;
      border-radius: 5px;
      box-shadow: 2px 2px 3px 0 $accent-dark;
      overflow: hidden;

      max-height: 35vh;

      @include responsive(mobile) {
        max-height: 95vw;
      }

      @include responsive(desktopSmallerThan) {
        flex-basis: 95%;
      }

      @include responsive(desktop) {
        flex-basis: 45%;
      }

      @include responsive(thiccc) {
        flex-basis: 28%;
      }

      .news-articleHeader {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background-color: $primary-background-transparent;
        border-bottom: 3px solid $accent-bright;
        text-overflow: ellipsis;
      }
      .news-articleTitle {
        font-size: $size-large !important;
        font-weight: bold !important;
        flex-basis: 100%;
        white-space: nowrap;
        max-width: 100%;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .news-articleImage {
        max-width: 100%;
        overflow: hidden;
        height: 30vh;
        background-size: cover;
        background-position: center;
        background-color: $primary;

        @include responsive(mobile) {
          height: 90vw;
        }
      }

      .news-articlePreview {
        display: none;
      }
    }

    &.news-articlepreview {
      padding-top: 1rem;
      max-height: 80vh;
      overflow: hidden;
      position: relative;
      width: 100%;

      .news-articleDate {
        flex-basis: 100%;
      }

      .news-articleImage {
        display: none;
      }

      .news-articlePreview {
        margin-bottom: 1rem;
      }

      .news-articleRead {
        background-color: $secondary-text;
        color: $secondary;
        width: 100%;
        display: block;
        position: absolute;
        bottom: 0;
        text-align: center;
      }
    }
  }
}
