@import "../../../Styles/master";

.footer-eat {
  background-color: $eat-primary !important;
  color: $eat-secondary-text !important;

  .footer-link {
    color: $eat-secondary-text !important;

    &:hover,
    &.footer-linkActive {
      color: $eat-secondary !important;
    }
  }

  .footer-info {
    #footer-copy,
    #footer-builtLink {
      color: $eat-secondary-text !important;
    }

    #footer-builtLink:hover {
      color: $ab-theme-medium !important;
    }
  }
}

.footer {
  transition: all 0.3s linear;
  background-color: $secondary;
  color: $secondary-text;

  .footer-imageContainer {
    padding: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .footer-bikeGraphic {
      height: 4rem;
    }
  }

  .footer-links {
    display: flex;
    justify-content: ceneter;
    align-items: center;

    @include responsive(desktopSmallerThan) {
      flex-wrap: wrap;
    }

    .footer-link {
      flex-basis: 100%;
      font-family: $link-font;
      font-weight: 500;
      letter-spacing: 2px !important;
      font-size: $size-small;
      text-decoration: none;
      text-align: center;
      color: $secondary-text;
      font-weight: 600;
      transition: color 0.3s linear;
      padding-bottom: 0.5rem;

      @include responsive(desktop) {
        flex-basis: auto;
        flex-grow: 1;
      }

      &:hover,
      &.footer-linkActive {
        color: $accent-bright;
      }
    }
  }

  .footer-info {
    padding-top: 0.5rem;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    padding-bottom: 0.1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-family: $standard-font;
    font-size: $size-tiny;
    position: relative;

    @include responsive(desktopSmallerThan) {
      height: auto;
      justify-content: center;
      padding-top: 0.25rem !important;

      #footer-socialIcons,
      #footer-copy,
      #footer-built {
        flex-basis: 100%;
        text-align: center;
      }

      #footer-socialIcons {
        padding-bottom: 0.1rem;
        width: auto !important;
        position: relative !important;
      }

      #footer-copy {
        padding-bottom: 0.25rem;
      }

      #footer-built {
        margin-left: 0;
      }
    }

    #footer-copy {
      padding-left: 0.25rem;
      color: $secondary-text;
      font-weight: 600;
    }

    #footer-socialIcons {
      justify-content: center;
      flex-grow: 1;
    }

    #footer-built {
      font-family: $builtByLink-font;
      font-weight: 600;
      margin-left: auto;
      padding-right: 0.15rem;

      #footer-builtLink {
        text-decoration: none;
        color: $secondary-text;
        transition: color 0.3s linear;

        &:hover {
          color: $accent-bright;
          text-decoration: underline;
        }
      }
    }
  }
}
